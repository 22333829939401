import {
    CloseContainer,
    CloseIcon,
    Container,
    ContentImage,
    DownloadButtonContainer,
    DownloadButtonText,
    DownloadText,
    DownloadTextContainer,
    InnerContainer,
    LivestreamEllipse,
    NoiceLogo,
} from '@/components/DownloadPrompt/styles'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import { compressedUserPhoto } from '@/helpers/compressedUserPhoto'
import { EventTracking } from '@/helpers/eventTracking'
import {
    setCurrentContentId,
    setShowDownloadPrompt,
} from '@/store/actions/player'
import * as catalogSelectors from '@/store/selectors/catalog'
import * as contentSelectors from '@/store/selectors/content'
import * as livestreamSelectors from '@/store/selectors/livestream'
import * as playerSelectors from '@/store/selectors/player'
import { APPSFLYER_ONELINK_WEB_TO_APP_URL } from '@/utils/constants'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isIOS, isIPad13, isMacOs } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

// import './styles.css'

const DownloadModal = () => {
    const dispatch = useDispatch()
    const router = useRouter()
    const { data: session } = useSession()
    const showDownloadPrompt = useSelector(playerSelectors._showDownloadPrompt)
    const [pathname, setPathname] = useState('')

    const showDownloadPromptText = useSelector(
        playerSelectors._showDownloadPromptText
    )
    const catalogData = useSelector(catalogSelectors._catalogData)
    const contentData = useSelector(contentSelectors._contentData)
    const livestreamData = useSelector(livestreamSelectors._livestreamData)
    const playlistDetails = useSelector(playerSelectors._playlistDetails)
    const currentContentId = useSelector(playerSelectors._currentContentId)
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setPathname(window.location.pathname)
        }
    }, [])

    const renderDownloadPromptImage = useCallback(() => {
        /*
        if (playing) {
            return (
                playlist?.filter(
                    (contentData) => contentData?.id === currentTrackId
                )[0]?.image ||
                playlist?.filter(
                    (contentData) => contentData?.id === currentTrackId
                )[0]?.catalog?.image ||
                catalogData?.image
            )
        } else 
        */
        if (pathname.includes('catalog')) {
            return catalogData?.image
        } else if (pathname.includes('livestream')) {
            const livestreamDataHost =
                livestreamData?.roomParticipants?.length &&
                livestreamData?.roomParticipants?.find(
                    (user) => user.type === 'host'
                )
            return compressedUserPhoto(livestreamDataHost?.user)
        } else if (pathname.includes('playlist')) {
            return playlistDetails?.image
        } else if (pathname.includes('genre')) {
            return '/img/emptystate_downloadnoice.svg'
        } else {
            return (
                contentData?.image ||
                catalogData?.image ||
                '/img/emptystate_downloadnoice.svg'
            )
        }
    }, [
        pathname,
        catalogData?.image,
        livestreamData?.roomParticipants,
        playlistDetails?.image,
        contentData?.image,
    ])

    const isStretched = useMemo(
        () => pathname.includes('noicebook') || pathname.includes('chapter'),
        [pathname]
    )
    const isLivestream = pathname.includes('livestream')

    const handleDownload = () => {
        const urlParams = router.asPath.slice(1).split('/')

        let DYNAMIC_APPSFLYER_URL = window.AF_SMART_SCRIPT_RESULT?.clickURL

        let WEB_TO_APP_LINK = APPSFLYER_ONELINK_WEB_TO_APP_URL

        if (urlParams.length) {
            let deep_link_sub1 = urlParams[0]
            let deep_link_value =
                urlParams[1] || router.query.contentId || router.query.catalogId
            if (currentContentId) {
                deep_link_sub1 = 'content'
                deep_link_value = currentContentId
            }
            DYNAMIC_APPSFLYER_URL = `${DYNAMIC_APPSFLYER_URL}&deep_link_sub1=${deep_link_sub1}&deep_link_value=${deep_link_value}`
            WEB_TO_APP_LINK = `${WEB_TO_APP_LINK}&deep_link_sub1=${deep_link_sub1}&deep_link_value=${deep_link_value}`
        }

        let eventParams = {}
        if (isMacOs || isIPad13 || isIOS) {
            eventParams = {
                destination: 'download',
            }

            window.location.href = DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK
        } else {
            eventParams = {
                destination: 'open app',
            }

            let deepUrl = DYNAMIC_APPSFLYER_URL || WEB_TO_APP_LINK

            window.location.href = deepUrl
        }

        if (session?.token) {
            AmplitudeEventTracking('web to app', '', session)
            EventTracking('web_to_app', eventParams, session)
        }
    }
    if (showDownloadPrompt) {
        return (
            <Container>
                <div
                    className="modal"
                    role="dialog"
                    style={{
                        display: 'flex',
                        position: 'absolute',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="modal-dialog" role="document">
                        <div
                            className="modal-content"
                            style={{ minWidth: '400px', padding: '10px' }}
                        >
                            <div className="modal-body">
                                <InnerContainer>
                                    <CloseContainer>
                                        <CloseIcon
                                            src="/img/close.svg"
                                            onClick={() => {
                                                dispatch(
                                                    setShowDownloadPrompt(false)
                                                )
                                                dispatch(
                                                    setCurrentContentId('')
                                                )
                                            }}
                                        />
                                    </CloseContainer>
                                    <NoiceLogo
                                        loading="lazy"
                                        src={'/img/new-noice-logo.svg'}
                                    />
                                    {isLivestream ? (
                                        <LivestreamEllipse>
                                            <ContentImage
                                                loading="lazy"
                                                isLivestream={isLivestream}
                                                src={
                                                    renderDownloadPromptImage() ||
                                                    '../img/default-user.svg'
                                                }
                                            />
                                        </LivestreamEllipse>
                                    ) : (
                                        <ContentImage
                                            loading="lazy"
                                            isStretched={isStretched}
                                            src={renderDownloadPromptImage()}
                                        />
                                    )}

                                    <DownloadTextContainer>
                                        <DownloadText>
                                            {showDownloadPromptText}
                                        </DownloadText>
                                    </DownloadTextContainer>

                                    <DownloadButtonContainer
                                        onClick={handleDownload}
                                    >
                                        <DownloadButtonText>
                                            Kunjungi Aplikasi
                                        </DownloadButtonText>
                                    </DownloadButtonContainer>
                                </InnerContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
    return <></>
}

export default DownloadModal
